<template>
  <div :class="$style.reviews">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectReview"
          @handleFindItems="handleFindReviews"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button type="primary" @click="$router.push('reviews/create')">
        Создать
      </el-button>
    </div>
    <el-table :data="reviews" stripe>
      <el-table-column prop="orderField" label="Приоритет" width="120">
      </el-table-column>
      <el-table-column prop="name" label="Имя" width="240"></el-table-column>
      <el-table-column prop="description" label="Описание" width="240">
      </el-table-column>
      <el-table-column prop="content" label="Контент" show-overflow-tooltip>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="'reviews/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import notifications from '@/mixins/notifications'

export default {
  components: {
    ActionButtons,
    Autocomplete,
  },
  mixins: [notifications],
  data() {
    return {
      reviews: [],
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.ReviewsActions.getList({
          limit: this.limit,
          page: this.page,
          search: this.search,
        })
      if (error) return
      this.reviews = value.data
      this.total = value.meta.count

      loading.close()
    },
    async remove(id) {
      const isConfirm = confirm('Вы точно хотите удалить отзыв?')
      if (!isConfirm) {
        return
      }
      const { error } = await delivery.ContentServiceCore.ReviewsActions.delete(
        id,
      )
      if (!error) {
        this.showNotification('Отзыв успешно удалён', 'success')
        await this.getList()
      }
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.ReviewsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async handleFindReviews() {
      await this.getList()
    },
    handleSelectReview(selectedSeo) {
      this.$router.push('/addwine/content/reviews/' + selectedSeo.id)
    },
  },
}
</script>

<style lang="scss" module>
.reviews {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;

    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
    .button {
      border-radius: 0;
    }
  }

  .pagination {
    @include pagination;
  }
}

.checkbox {
  pointer-events: none;
}
</style>
